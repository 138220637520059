exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-demo-jsx": () => import("./../../../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-integration-jsx": () => import("./../../../src/pages/integration.jsx" /* webpackChunkName: "component---src-pages-integration-jsx" */),
  "component---src-pages-onboarding-jsx": () => import("./../../../src/pages/onboarding.jsx" /* webpackChunkName: "component---src-pages-onboarding-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-use-cases-enterprises-jsx": () => import("./../../../src/pages/use-cases/enterprises.jsx" /* webpackChunkName: "component---src-pages-use-cases-enterprises-jsx" */),
  "component---src-pages-use-cases-smes-jsx": () => import("./../../../src/pages/use-cases/smes.jsx" /* webpackChunkName: "component---src-pages-use-cases-smes-jsx" */)
}

